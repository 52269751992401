/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* body {
  overflow-y: hidden;
  overflow-x: hidden;
} */

.ant-form-item .ant-form-item-label > label {
  width: 25vh;
}
.driverdetails .ant-form-item .ant-form-item-label > label {
  color: white;
}
.checked_input {
  border: 3px solid #e2c7e3;
  padding: 10px;
  border-radius: 3px;
  background: #e2c7e3;
  width: 80%;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.ant-btn-primary {
  background-color: #1677ff !important;
}
.ant-btn-warning {
  background-color: #eca52b !important;
}
.ant-btn-success {
  background-color: #28a745 !important;
}
/* Pagination */
.page-item.active .page-link {
  background-color: #fa9c23;
  border-color: #fa9c23;
}

.page-link {
  color: #fa9c23;
}

.page-link:hover {
  color: #fa9c23;
}
.active {
  color: red;
}
a.ant-dropdown-trigger {
  color: white;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  background: #aaaacd;
}
span.anticon.anticon-bell {
  color: #f30646;
  font-size: 19px;
  padding-left: 34px;
}
